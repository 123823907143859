import React, { Component } from "react";
import styled from "styled-components";

class Picture extends Component {
  constructor(props) {
    super(props);
    this.srcImage = React.createRef();
    this.altImage = React.createRef();
  }
  render() {
    const src = this.props.src;
    const alt = this.props.alt;
    const removePlaceholder = () => {
      this.altImage.current.remove();
      this.srcImage.current.style.visibility = "visible";
      this.srcImage.current.style.position = "relative";
    }

    return (
      <Container>
        <Image
          ref={this.altImage}
          style={{ position: "relative" }}
          src={alt}
        />
        <Image
          ref={this.srcImage}
          src={src}
          onLoad={removePlaceholder}
          style={{ position: "absolute", visibility: "hidden", top: 0, left: 0, zIndex: "-1" }}
        />
      </Container>
    );
  }
}

const Container = styled.div`
  padding-bottom: none;
  max-width: 925px;
  margin-bottom: 20px;

  position: relative;

  @media (max-width: 1000px) {
    max-width: 95%;
    padding: 0px;
  }
`;

const Image = styled.img`
  max-width: inherit;
  margin-bottom: -5px;
  border-top: 5px solid #febe10;

  @media (max-width: 1000px) {
    max-width: 100%;
    margin-bottom: -10px;
  }
`;

export default Picture;
