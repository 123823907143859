import React, { Component } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import styled from "styled-components";
import axios from "axios";

class ContactForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      recaptcha: "",
      recaptchaPassed: false,
      name: "",
      company: "",
      telephone: "",
      email: "",
      message: "",
      error: false,
      sent: false,
      showPopup: false
    };

    this.onReCAPTCHA = this.onReCAPTCHA.bind(this);
    this.sendFormData = this.sendFormData.bind(this);
  }

  onReCAPTCHA(value) {
    this.setState({ recaptcha: value });

    const data = {
      apiKey: process.env.LINNCO_API_KEY,
      recaptcha: value
    };

    axios({
      method: "post",
      url: "https://linncoinc.com/.netlify/functions/recaptcha-validation",
      data: data
    })
      .then(res => {
        if (res.status === 200) {
          console.log("passed captcha");
          this.setState({ recaptchaPassed: true, showPopup: false });
          this.sendFormData();
        } else {
          console.log("failed captcha");
          this.setState({ showPopup: false });
        }
      })
      .catch(err => {
        console.log(err);
        this.setState({ showPopup: false });
      });
  }

  sendFormData() {
    if (!this.state.recaptchaPassed) {
      // never send without recaptcha
      return;
    }

    const content = {
      apiKey: process.env.LINNCO_API_KEY,
      name: this.state.name,
      company: this.state.company,
      telephone: this.state.telephone,
      email: this.state.email,
      message: this.state.message
    };

    console.log("sending form data...");

    axios({
      method: "post",
      url: "https://linncoinc.com/.netlify/functions/contactus-submit",
      data: content
    }).then(res => {
      if (res.status === 200) {
        this.setState({ sent: true });
        console.log("email sent.");
        this.setState({
          recaptcha: "",
          recaptchaPassed: false,
          name: "",
          company: "",
          telephone: "",
          email: "",
          message: "",
          sent: false
        });
      } else {
        this.setState({ error: true });
        console.log(`send failure. status code: ${res.status}`);
      }
    });
  }

  render() {
    // sendButton disabled until requirements are met, then its enabled and restyled
    const sendButton =
      this.state.name && this.state.telephone && this.state.message ? (
        <Submit
          className="submit"
          type="submit"
          value="Send"
          style={{
            border: "2px solid #febe10",
            color: "#febe10"
          }}
        />
      ) : (
          <Submit className="submit" type="submit" value="Send" />
        );

    return (
      <Container>
        <Form
          className="form"
          ref={el => (this.formRef = el)}
          onSubmit={e => {
            e.preventDefault();
            this.setState({ showPopup: true });
          }}
        >
          <Input
            className="name"
            required
            type="text"
            name="name"
            placeholder="Full Name (required)"
            value={this.state.name}
            onChange={e => this.setState({ name: e.target.value })}
          />
          <Input
            className="company"
            type="text"
            name="company"
            placeholder="Company"
            value={this.state.company}
            onChange={e => this.setState({ company: e.target.value })}
          />
          <Input
            className="telephone"
            required
            type="text"
            name="telephone"
            placeholder="Phone Number (required)"
            value={this.state.telephone}
            onChange={e => this.setState({ telephone: e.target.value })}
          />
          <Input
            className="email"
            type="text"
            name="email"
            placeholder="Email"
            value={this.state.email}
            onChange={e => this.setState({ email: e.target.value })}
          />
          <Textarea
            className="message"
            required
            type="text"
            rows="5"
            name="message"
            placeholder="Message (required)"
            value={this.state.message}
            onChange={e => this.setState({ message: e.target.value })}
          />
          {sendButton}
        </Form>
        {this.state.showPopup && (
          <PopupWrapper onClick={() => this.setState({ showPopup: false })}>
            <PopupContent>
              <ReCAPTCHA
                className="recaptcha"
                ref={el => (this.grecaptcha = el)}
                sitekey={process.env.GOOGLE_RECAPTCHA_CLIENT_SECRET}
                onChange={this.onReCAPTCHA}
                size="normal"
              />
            </PopupContent>
          </PopupWrapper>
        )}
      </Container>
    );
  }
}

const Container = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  margin-top: 20px;
`;

const Form = styled.form`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 500px;

  @media (max-width: 575px) {
    width: inherit;
  }
`;

const Input = styled.input`
  border: 2px solid #febe10;
  min-width: 250px;
  margin-bottom: 20px;
  padding: 10px;
  -webkit-box-shadow: none;
  box-shadow: none;
  font-size: 0.8rem;
  width: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  color: #f3f3f3;
  background-color: #313131;
`;

const Textarea = styled.textarea`
  border: 2px solid #febe10;
  min-width: 250px;
  margin-bottom: 20px;
  padding: 10px;
  resize: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  font-size: 0.8rem;
  width: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  color: #f3f3f3;
  background-color: #313131;
`;

const Submit = styled.input`
  border: 2px solid #707070;
  width: 100%;
  color: inherit;
  min-width: 250px;
  cursor: pointer;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  padding: 10px;
  font-size: 0.8rem;
  background-color: #313131;
  color: #f3f3f3;
`;

const PopupWrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.5);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
`;

const PopupContent = styled.div`
  padding: 60px;
  margin: auto;
  background: #f3f3f3;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-top: 10px solid #febe10;

  @media (max-width: 550px) {
    width: 60%;
  }
`;

export default ContactForm;
