import React, { Component } from "react";
import styled from "styled-components";

import Button from "./Button";

class Careers extends Component {
  constructor() {
    super();

    this.state = {
      openings: [
        {
          id: 0,
          title: "Heavy Equipment Operator",
          location: "Sartell, MN"
        },
//         {
//           id: 1,
//           title: "Foreman",
//           location: "Sartell, MN"
//         },
//         {
//           id: 2,
//           title: "Estimator / Project Manager",
//           location: "Sartell, MN"
//         },
        {
          id: 1,
          title: "CDL Driver (Class A)",
          location: "Sartell, MN",
          url: "https://www.indeed.com/job/cdl-truck-driver-a3408904706d7422"
        }
      ]
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    document.title = "LinnCo | Careers";
  }

  render() {
    const positions = this.state.openings.map((opening, i) => {
      return (
        <JobCard
          key={opening.id}
          title={opening.title}
          location={opening.location}
          url={opening.url || ""}
          description={opening.description || ""}
        />
      );
    });
    const content = <CardsContainer>{positions}</CardsContainer>;
    return (
      <Container>
        <HeaderText>Careers</HeaderText>
        <PageText>
          LinnCo is now seeking qualified candidates for the following
          positions. LinnCo is a sitework contractor providing comprehensive
          demolition and excavating services throughout the Midwest.
        </PageText>
        {content}
      </Container>
    );
  }
}

const Container = styled.div`
  width: inherit;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 60px;
  margin-bottom: 60px;
`;
const CardsContainer = styled.div`
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-top: 30px;
`;
const HeaderText = styled.h1`
  font-size: 2rem;
`;
const PageText = styled.p`
  font-size: 1.2rem;
  margin-top: 20px;
  margin-bottom: 10px;
  line-height: 2rem;
  text-align: center;
  max-width: 95%;

  @media (max-width: 700px) {
    text-align: left;
  }
`;

class JobCard extends Component {
  render() {
    const { title, location, url } = this.props;

    return (
      <CardContainer>
        <DescriptionContainer>
          <MainText>{title}</MainText>
          <SubText>{location}</SubText>
        </DescriptionContainer>
        <SubmitContainer>
          <Button
            to={url ? url : "mailto: apply@linncoinc.com"}
            text={url ? "Apply on Indeed" : "Send Resume"}
          />
        </SubmitContainer>
      </CardContainer>
    );
  }
}

const CardContainer = styled.div`
  max-width: 100%;
  height: 100px;
  background-color: #313131;
  color: #f3f3f3;
  border-left: 5px solid #febe10;
  margin-bottom: 30px;
  padding: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;

  @media (max-width: 700px) {
    height: 125px;
  }

  :hover {
    -webkit-box-shadow: 0 0px 4px #313131;
    box-shadow: 0 0px 4px #313131;
  }
`;
const DescriptionContainer = styled.div``;
const SubmitContainer = styled.div`
  -ms-flex-item-align: end;
  align-self: flex-end;
  margin-bottom: 8px;
`;

const MainText = styled.h1`
  font-size: 1.2rem;
  color: #f3f3f3;
`;
const SubText = styled.p`
  color: #f3f3f3;
`;

export default Careers;
