import React from "react";
import styled from "styled-components";

const ContactDetails = () => {
  return (
    <Container>
      <HeadingText>Contact Us</HeadingText>
      <ItemsContainer>
        <Item>
          <MainText>Phone:</MainText>
          <TextLink href="tel:320-272-8288">
            <SubText>320.272.8288</SubText>
          </TextLink>
        </Item>
        <Item>
          <MainText>Fax:</MainText>
          <SubText>320.640.4511</SubText>
        </Item>
        <Item>
          <MainText>Address:</MainText>
          <TextLink href="https://maps.app.goo.gl/j18DHQf227cdefHs8">
            <SubText>
              7316 Ridgewood Road
              <br /> St Cloud, MN 56303
            </SubText>
          </TextLink>
        </Item>
      </ItemsContainer>
    </Container>
  );
};

const Container = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 30px;
`;
const ItemsContainer = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
`;
const Item = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 10px;
  width: 300px;
  color: #313131;
`;
const HeadingText = styled.h1`
  font-size: 2rem;
  text-align: left;
  line-height: 2rem;
  margin-bottom: 20px;
  color: #313131;
`;
const MainText = styled.p`
  font-size: 1.2rem;
  color: #313131;
  line-height: 2rem;
  font-weight: 600;
`;
const SubText = styled.p`
  font-size: 1.2rem;
  color: #313131;
  line-height: 2rem;
  text-align: center;
`;

const TextLink = styled.a`
  text-decoration: none;
  color: #313131;
`;

export default ContactDetails;
