import React, { Component } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

class DesktopMenu extends Component {
  render() {
    return (
      <Container>
        <StyledLink to="/">Home</StyledLink>
        <StyledLink to="/services">Services</StyledLink>
        {/* <StyledLink to="/about-us">About Us</StyledLink> */}
        <StyledLink to="/careers">Careers</StyledLink>
        <StyledLink to="/contact-us">Contact Us</StyledLink>
        <StyledIcon
          href="tel: 1-320-272-8288"
          className="fas fa-phone"
          aria-hidden="true"
        />
      </Container>
    );
  }
}

const Container = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  margin-top: -3px;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: #f3f3f3;
  padding-right: 10px;
  padding-left: 10px;
  font-weight: 500;
  font-size: 14px;
  padding-top: 28px;
  margin-top: -28px;
  padding-bottom: 30px;
  margin-bottom: -28px;

  :hover {
    color: #febe10;
    background-color: #2a2a2a;
  }
`;

const StyledIcon = styled.a`
  text-decoration: none;
  color: #f3f3f3;
  padding-right: 20px;
  padding-left: 20px;
  padding-top: 30px;
  margin-top: -28px;
  padding-bottom: 30px;
  margin-bottom: -28px;

  :hover {
    color: #febe10;
    background-color: #2a2a2a;
  }
`;

export default DesktopMenu;
