import React, { Component } from "react";
import styled from "styled-components";

import ContactForm from "../contactForm/ContactForm";
import ContactDetails from "../contactDetails/ContactDetails";

class ContactUs extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
    document.title = "LinnCo | Contact Us";
  }

  render() {
    return (
      <Container>
        <ContactDetails />
        <LargeText>General Inquiry</LargeText>
        <ContactForm />
      </Container>
    );
  }
}

const Container = styled.div`
  width: inherit;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-left: 0px;
  margin-top: 60px;
  margin-bottom: 60px;
`;

const LargeText = styled.h1`
  font-size: 2rem;
  font-weight: 600;
  color: #313131;
`;

export default ContactUs;
