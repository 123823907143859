import React, { Component } from "react";
import styled from "styled-components";
import logo from "../../logo-md-yellow.png";
import DesktopMenu from "../menu/DesktopMenu";
import MobileMenu from "../menu/MobileMenu";

class Header extends Component {
  state = {
    width: this.props.width
  };

  componentWillReceiveProps(nextProps) {
    if (this.state.width === nextProps.width) return;

    this.setState({ width: nextProps.width });
  }

  render() {
    const { width } = this.state;
    const isMobile = width <= 700;

    return (
      <Container>
        <ContentWrapper>
          <a href="/">
            <Logo src={logo} />
          </a>
          <MenuContainer>
            {isMobile ? <MobileMenu /> : <DesktopMenu />}
          </MenuContainer>
        </ContentWrapper>
      </Container>
    );
  }
}

const Container = styled.div`
  height: 65px;
  min-height: 75px;
  background-color: #313131;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: -webkit-sticky;
  position: sticky;
  z-index: 1000;
  top: 0;
  -webkit-box-shadow: 0 0px 6px #313131;
  box-shadow: 0 0px 6px #313131;
  /* padding-bottom: 10px; */
`;

const ContentWrapper = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 1000px;
  color: #febe10;
  margin-top: 10px;
  margin-bottom: 10px;

  @media (max-width: 1115px) {
    width: 90vw;
  }
`;

const Logo = styled.img`
  max-width: 100%;
  height: 45px;
  margin-left: -2px;
  margin-top: 10px;
  margin-bottom: 10px;
  image-rendering: -webkit-optimize-contrast;
`;

const MenuContainer = styled.div`
  margin-right: 0px;

  @media (max-width: 700px) {
    margin-right: 0px;
  }
`;

export default Header;
