import React, { Component } from "react";
import styled from "styled-components";
import logo from "../../logo-sm-yellow.png";

const phoneNumberLink = "tel: 1-320-272-8288";
const emailLink = "mailto: contact@linncoinc.com";
const addressLink = "https://maps.app.goo.gl/j18DHQf227cdefHs8";

class Footer extends Component {
  render() {
    return (
      <Container>
        <ContentWrapper>
          <Copyright />
          <LoginButton href="https://login.microsoftonline.com">
            Employee Login
          </LoginButton>
          <ContactInfo />
        </ContentWrapper>
      </Container>
    );
  }
}

// FOOTER & STYLED COMPONENTS
const Container = styled.div`
  min-height: 75px;
  background-color: #313131;
  color: #febe10;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
`;

const ContentWrapper = styled.div`
  width: 1000px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 10px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
`;

const LoginButton = styled.a`
  border: 1px solid #717171;
  color: #717171;
  text-decoration: none;
  font-size: 0.8rem;
  padding: 5px;
  border-radius: 1px;
  margin-top: 20px;

  :hover {
    color: #febe10;
    border-color: #febe10;
  }
`;

// COPYRIGHT & STYLED COMPONENTS
const Copyright = () => {
  const date = new Date();
  const currentYear = date.getFullYear()
  return (
    <CopyrightWrapper>
      <CopyrightLogo src={logo} />
      <CopyrightText>©{currentYear} LinnCo, Inc. All rights reserved.</CopyrightText>
    </CopyrightWrapper>
  );
};

const CopyrightWrapper = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-top: 10px;
  margin-bottow: 10px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
`;

const CopyrightLogo = styled.img`
  max-width: 100%;
  height: 35px;
  margin-bottom: 5px;
  image-rendering: -webkit-optimize-contrast;
`;

const CopyrightText = styled.p`
  color: #717171;
  font-size: 10px;
`;

// CONTACT INFO & STYLED COMPONENTS

const ContactInfo = () => {
  return (
    <ContactWrapper>
      <ContactLink href={phoneNumberLink}>
        <Icon className="fas fa-phone" aria-hidden="true" />
        &nbsp;&nbsp;(320) 272-8288
      </ContactLink>
      <ContactLink href={emailLink}>
        <Icon className="fa fa-envelope" aria-hidden="true" />
        &nbsp;&nbsp;contact@linncoinc.com
      </ContactLink>
      <ContactLink href={addressLink}>
        &nbsp;
        <Icon className="fa fa-map-marker" aria-hidden="true" />
        &nbsp;
        <AddressContainer>
          <AddressText>
            &nbsp;7316 Ridgewood Road
            <br />
            &nbsp;St Cloud, MN 56303
          </AddressText>
        </AddressContainer>
      </ContactLink>
    </ContactWrapper>
  );
};

const ContactWrapper = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-top: 20px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-right: 0px;
`;
const ContactLink = styled.a`
  color: #febe10;
  text-decoration: none;
  font-size: 12px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 10px;
  color: #f3f3f3;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;

  :hover {
    color: #e0e0e0;
  }
`;
const Icon = styled.i`
  color: #febe10;
  -ms-flex-item-align: center;
  align-self: center;
`;
const AddressContainer = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
`;
const AddressText = styled.p`
  -ms-flex-item-align: start;
  align-self: flex-start;
  color: #f3f3f3;

  :hover {
    color: #e0e0e0;
  }
`;

export default Footer;
