import React, { Component } from "react";
import "./PageNotFound.css";

class PageNotFound extends Component {
  render() {
    return (
      <div className="pageNotFound-container">
        <h1>404</h1>
        <p className="main-text">Page not found.</p>
      </div>
    );
  }
}

export default PageNotFound;
