import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import styled from "styled-components";

// Component imports
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import PageNotFound from "./components/pageNotFound/PageNotFound";
import ContactUs from "./components/contactUs/ContactUs";
import LandingPage from "./components/landingPage/LandingPage";
import Careers from "./components/careers/Careers";
import Services from "./components/services/Services";

if (process.env.NODE_ENV === "development") require("dotenv").config();

class App extends Component {
  constructor() {
    super();

    this.state = {
      width: window.innerWidth
    };

    this.handleWindowSizeChange = this.handleWindowSizeChange.bind(this);
  }

  componentWillMount() {
    window.addEventListener("resize", this.handleWindowSizeChange);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange);
  }

  handleWindowSizeChange() {
    this.setState({ width: window.innerWidth });
  }

  render() {
    return (
      <Router>
        <Container>
          <Header width={this.state.width} />

          <Content>
            <Switch>
              <Route exact={true} path="/" component={LandingPage} />
              <Route exact={true} path="/services" component={Services} />
              <Route exact={false} path="/careers" component={Careers} />
              <Route exact={true} path="/contact-us" component={ContactUs} />
              <Route component={PageNotFound} />
            </Switch>
          </Content>

          <Footer width={this.state.width} />
        </Container>
      </Router>
    );
  }
}

const Container = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-height: 100vh;
  min-width: 325px;
`;

const Content = styled.div`
  width: 1000px;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;

  @media (max-width: 1115px) {
    width: 95%;
  }
`;

export default App;
