import React from "react";
import styled from "styled-components";

const Button = ({ text, to }) => {
  return <ButtonLink href={to}>{text}</ButtonLink>;
};

const ButtonLink = styled.a`
  text-decoration: none;
  color: #f3f3f3;
  border: 1px solid #f3f3f3;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 10px;
  padding-top: 5px;
  padding-bottom: 8px;
  margin-bottom: -10px;
  min-width: 50px;
  text-align: center;
  -ms-flex-item-align: end;
  align-self: flex-end;
  margin-left: 20px;
  margin-top: 20px;

  :hover {
    color: #febe10;
    border: 1px solid #febe10;
    cursor: pointer;
  }
`;

export default Button;
