import React, { Component } from "react";
import styled from "styled-components";

class Services extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
    document.title = "LinnCo | Services";
  }

  render() {
    return (
      <Container>
        <PageTitle>Services</PageTitle>
        <ServiceContainer>
          <ServiceDescription>
            <ServiceTitle>Demolition</ServiceTitle>
            <DescriptionText>
              At LinnCo, demolition is our specialty. Our owners and field
              personnel bring extensive skills and experience estimating and
              managing demolition projects, as well as saving clients money. We
              strive to make all of our demolition jobs a turnkey process.
              <br />
              <br />
              Planning, permitting, environmental assessments, asbestos
              abatement, regulated waste removal, demolition and recycling.
              We've got you covered from start to finish.
            </DescriptionText>
          </ServiceDescription>
          <ServicesDetails>
            <ListTitle>Demolition services include the following:</ListTitle>
            <List>
              <ListItem>
                - Emergency Demolition Services and Disaster Relief
              </ListItem>
              <ListItem>- Industrial Demolition</ListItem>
              <ListItem>- Commercial Demolition</ListItem>
              <ListItem>- Residential Demolition</ListItem>
              <ListItem>- Total and Selective Demolition</ListItem>
              <ListItem>- Asset Recovery</ListItem>
              <ListItem>
                - Recycling and Salvage
                <br />
                <br />
              </ListItem>
            </List>

            <ListTitle>Our team has experience with:</ListTitle>
            <List>
              <ListItem>- Power Plants</ListItem>
              <ListItem>- Hospitals</ListItem>
              <ListItem>- Schools</ListItem>
              <ListItem>- Industrial Facilities</ListItem>
              <ListItem>- Manufacturing Facilities</ListItem>
              <ListItem>- Commercial Structures</ListItem>
              <ListItem>- Residential Housing</ListItem>
            </List>
          </ServicesDetails>
        </ServiceContainer>
        <ServiceContainer>
          <ServiceDescription>
            <ServiceTitle>Site Preparation</ServiceTitle>
            <DescriptionText>
              At LinnCo, our goal is to help our clients handle the entire
              sitework process.
              <br />
              <br />
              Planning, permitting, demo, clearing, grading, site restoration,
              clean-up. We've got you covered from start to finish.
            </DescriptionText>
          </ServiceDescription>
          <ServicesDetails>
            <ListTitle>Site Prep services include the following:</ListTitle>
            <List>
              <ListItem>- Mass Grading</ListItem>
              <ListItem>- Fine Grading</ListItem>
              <ListItem>- Site Demolition</ListItem>
              <ListItem>- Clearing/Grubbing</ListItem>
              <ListItem>- Site Grading</ListItem>
              <ListItem>- Building Pad Preparation</ListItem>
              <ListItem>- Pavement Preparation</ListItem>
              <ListItem>
                - Site Restoration
                <br />
                <br />
              </ListItem>
            </List>
            <ListTitle>Our team has experience with:</ListTitle>
            <List>
              <ListItem>- Soccer Fields</ListItem>
              <ListItem>- Football Fields</ListItem>
              <ListItem>- New Building Construction</ListItem>
              <ListItem>- New Park Construction</ListItem>
              <ListItem>- Box Culverts</ListItem>
              <ListItem>- Soil Remediation</ListItem>
            </List>
          </ServicesDetails>
        </ServiceContainer>
      </Container>
    );
  }
}

const Container = styled.div`
  width: inherit;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 60px;
  margin-bottom: 60px;
`;

const ServiceContainer = styled.div`
  width: 100%;
  background-color: #313131;
  color: #f3f3f3;
  line-height: 1.8rem;
  border-top: 5px solid #febe10;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 40px;

  @media (max-width: 1020px) {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
`;

const ServiceDescription = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  max-width: 45%;
  padding: 30px;
  padding-right: 0px;

  @media (max-width: 1020px) {
    max-width: 100%;
    padding: 30px;
  }
`;
const DescriptionText = styled.p`
  color: #e6e6e6;
`;
const ServicesDetails = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  max-width: 45%;
  padding: 30px;

  @media (max-width: 1020px) {
    padding-top: 0;
    max-width: 100%;
  }
`;
const ServiceTitle = styled.h1`
  font-size: 1.8rem;
  margin-bottom: 10px;
  color: #f3f3f3;
`;
const ListTitle = styled.h1`
  font-size 1.2rem;
  font-weight: 600;
  margin-bottom: 10px;
  color: #f3f3f3;
`;
const List = styled.ul`
  list-style-type: none;
  font-size: 0.9rem;
`;
const ListItem = styled.li`
  color: #e6e6e6;
`;

const PageTitle = styled.h1`
  font-size: 2rem;
  margin-bottom: 30px;
`;

export default Services;
