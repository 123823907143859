import React from "react";
import styled from "styled-components";

const ContactCard = ({ name, position, contactInfo }) => {
  const { office, cell, fax, email } = contactInfo;
  return (
    <Container>
      <PersonContainer>
        <Name>{name}</Name>
        <Position>{position}</Position>
      </PersonContainer>
      <NumberGroupContainer>
        <NumberContainer>
          <MainText>Email:</MainText>&nbsp;
          <SubText>
            <TextLink href={`mailto: ${email}`}>{email}</TextLink>
          </SubText>
        </NumberContainer>
        <NumberContainer>
          <MainText>Office:</MainText>&nbsp;
          <SubText>
            <TextLink href={`tel: ${office}`}>{office}</TextLink>
          </SubText>
        </NumberContainer>
        {!cell ? '' :
          <NumberContainer>
            <MainText>Cell:</MainText>&nbsp;
            <SubText>
              <TextLink href={`tel: ${cell}`}>{cell}</TextLink>
            </SubText>
          </NumberContainer>
        }
        <NumberContainer>
          <MainText>Fax:</MainText>&nbsp;<SubText>{fax}</SubText>
        </NumberContainer>
      </NumberGroupContainer>
    </Container>
  );
};

const Container = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  min-height: 100px;
  min-width: 220px;
  background-color: #313131;
  border-top: 5px solid #febe10;
  padding: 30px;
  margin-left: 30px;
  margin-right: 30px;
  color: #f3f3f3;
  margin: 30px;
  min-height: 180px;

  :hover {
    -webkit-box-shadow: 0px 1px 4px #313131;
    box-shadow: 0px 1px 4px #313131;
  }
`;
const PersonContainer = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
`;
const Name = styled.h1`
  color: #f3f3f3;
`;
const Position = styled.p`
  color: #f3f3f3;
`;
const NumberGroupContainer = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-top: 10px;
`;
const NumberContainer = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 5px;
`;
const MainText = styled.h1`
  color: #f3f3f3;
`;
const SubText = styled.p`
  color: #f3f3f3;
`;
const TextLink = styled.a`
  text-decoration: none;
  color: #f3f3f3;
`;

export default ContactCard;
