import React, { Component } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import logo from "../../logo-by.png";

class MobileMenu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      viewMenu: false
    };

    this.menuButtonClick = this.menuButtonClick.bind(this);
  }

  menuButtonClick() {
    this.setState({ viewMenu: !this.state.viewMenu });
  }

  render() {
    const viewMenu = this.state.viewMenu;
    if (viewMenu) {
      // prevent scrolling of the underlying page
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "scroll";
    }
    return (
      <Container>
        {viewMenu ? (
          <ContentWrapper onClick={this.menuButtonClick}>
            <StyledLink to="/">
              <Logo src={logo} alt="" />
            </StyledLink>
            <StyledLink to="/">HOME</StyledLink>
            <StyledLink to="/services">SERVICES</StyledLink>
            {/* <StyledLink to="/about-us">ABOUT US</StyledLink> */}
            <StyledLink to="/careers">CAREERS</StyledLink>
            <StyledLink to="/contact-us">CONTACT US</StyledLink>
            <MenuCloseButton
              className="fa fa-times"
              onClick={this.menuButtonClick}
            />
          </ContentWrapper>
        ) : (
          <MenuButtonsContainer>
            <StyledIcon
              href="tel: 1-320-272-8288"
              className="fas fa-phone"
              aria-hidden="true"
            />
            <MenuButtonIcon
              className="fa fa-bars"
              onClick={this.menuButtonClick}
            />
          </MenuButtonsContainer>
        )}
      </Container>
    );
  }
}

const Container = styled.div`
  margin-top: -2px;
`;

const ContentWrapper = styled.div`
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 2000;
  top: 0;
  left: 0;
  background-color: #f3f3f3;
  overflow-x: hidden;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-left: 0;
`;

const Logo = styled.img`
  height: 50px;
  margin-bottom: 10px;
  margin-top: -10px;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  font-size: 22px;
  color: #313131;
  padding: 10px;
  width: 100%;
  text-align: center;

  :hover {
    color: #717171;
  }
`;
const MenuButtonsContainer = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
`;
const StyledIcon = styled.a`
  text-decoration: none;
  cursor: pointer;
  padding: 15px;
  padding-right: 10px;
  color: #f3f3f3;

  :hover {
    color: #febe10;
  }
`;
const MenuButtonIcon = styled.a`
  cursor: pointer;
  padding: 15px;
  padding-right: 10px;
  color: #f3f3f3;

  :hover {
    color: #febe10;
  }
`;

const MenuCloseButton = styled.i`
  margin-top: 20px;
  color: #313131;
  cursor: pointer;

  :hover {
    color: #717171;
  }
`;

export default MobileMenu;
