import React, { Component } from "react";
import styled from "styled-components";

import hv130 from "../../d202-hv130-demo-ram.jpg";
import hv130Lazy from "../../d202-hv130-demo-ram-lazyload.jpg";
import d216 from "../../d226-1.png";

import ContactForm from "../contactForm/ContactForm";
import ContactCard from "../contactCard/ContactCard";
import ContactDetails from "../contactDetails/ContactDetails";
import Picture from "./Picture";

class LandingPage extends Component {
  state = {
    contactCards: [
      {
        name: "Nick Linnemann",
        position: "President / CEO",
        contactInfo: {
          email: "nick@linncoinc.com",
          office: "320-272-8288",
          cell: "320-406-7757",
          fax: "320-640-4511"
        }
      },
      {
        name: "Michael Lohrman",
        position: "Chief Financial Officer",
        contactInfo: {
          email: "michael@linncoinc.com",
          office: "320-272-8288",
          fax: "320-640-4511"
        }
      },
      {
        name: "Alecia Prom",
        position: "Controller",
        contactInfo: {
          email: "alecia@linncoinc.com",
          office: "320-272-8288",
          fax: "320-640-4511"
        }
      },
      {
        name: "Teresa Stauber",
        position: "Office Manager",
        contactInfo: {
          email: "teresa@linncoinc.com",
          office: "320-272-8288",
          fax: "320-640-4511"
        }
      },
      {
        name: "Mike Elbert",
        position: "Estimator / Project Manager",
        contactInfo: {
          email: "mike@linncoinc.com",
          office: "320-272-8288",
          cell: "320-423-8144",
          fax: "320-640-4511"
        }
      },
      {
        name: "John Linnemann",
        position: "Estimator / Project Manager",
        contactInfo: {
          email: "john@linncoinc.com",
          office: "320-272-8288",
          cell: "320-290-1511",
          fax: "320-640-4511"
        }
      },
      {
        name: "Jared Katke",
        position: "Estimator / Project Manager",
        contactInfo: {
          email: "jared@linncoinc.com",
          office: "320-272-8288",
          cell: "320-260-3309",
          fax: "320-640-4511"
        }
      }
    ]
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    document.title = "LinnCo | Demolition & Sitework";
  }

  render() {
    const contactCards = this.state.contactCards.map((contact, i) => {
      return (
        <ContactCard
          key={i}
          name={contact.name}
          position={contact.position}
          contactInfo={contact.contactInfo}
        />
      );
    });
    return (
      <Container>
        <Picture src={d216} alt="" />
        <AboutContainer>
          <SmallText>
            LinnCo, Inc. is a licensed and bonded contractor specializing in
            demolition and sitework throughout the Midwest. At LinnCo, we
            focus on safety while drawing upon our experience to complete a wide
            range of projects and save our clients time and money.
          </SmallText>
        </AboutContainer>
        <ContactCardsContainer>{contactCards}</ContactCardsContainer>
        <ContactContainer>
          <ContactDetails />
          <LargeText>General Inquiry</LargeText>
          <ContactForm />
        </ContactContainer>
      </Container>
    );
  }
}

const containerPadding = "60px";

const Container = styled.div`
  width: inherit;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-height: 100vh;
  color: #313131;
  line-height: 1.5rem;
  margin-right: 10px;
  margin-left: 10px;
  margin-top: ${containerPadding};
`;
const AboutContainer = styled.div`
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
`;
const SmallText = styled.p`
  font-size: 1.2rem;
  font-color: #313131;
  text-align: center;
  max-width: 100%%;
  line-height: 2.5rem;
  margin-left: 25px;
  margin-right: 25px;

  @media (max-width: 700px) {
    text-align: left;
    margin-left: 10px;
    margin-right: 10px;
  }
`;
const ContactCardsContainer = styled.div`
  width: 110%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-top: 40px;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
`;
const ContactContainer = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  padding-top: 40px;
  padding-bottom: ${containerPadding};
  margin-bottom: 30px;
`;
const LargeText = styled.h1`
  font-size: 2rem;
  text-align: center;
  margin-bottom: 10px;
  margin-top: 30px;
`;

export default LandingPage;
